import styled from 'styled-components';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import ICON_1 from '../../static/images/mail.svg';

import { getUTCDate } from '../../library/date.lib';
import { useToasts } from 'react-toast-notifications';
import Color from 'color';

const delay = 5000;

function Main() {
  const history = useHistory();
  const { addToast } = useToasts();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [bannerList, setBannerList] = useState<any[]>([]);
  const [exhibition, setExhibition] = useState<any>(null);
  const [artFairList, setArtFairList] = useState<any[]>([]);

  const [bannerIndex, setBannerIndex] = useState(0);
  const timeoutRef = useRef<any>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const getList = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner/on`)
      .then((response) => {
        setBannerList(response.data.data);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/onview`)
      .then(async (response) => {
        setExhibition(response.data.data);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair-main`)
      .then((response) => {
        setArtFairList(response.data.data);
      });
  };

  const postMail = () => {
    if (email === '' || firstName === '' || lastName === '') {
      addToast('Please enter your information.', { appearance: 'warning' });

      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/mail`, {
        email,
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => {
        addToast('Thank you for subscribing to our mailing service.', {
          appearance: 'success',
        });
        setEmail('');
        setFirstName('');
        setLastName('');
      });
  };

  useEffect(() => {
    if (bannerList.length >= 2) {
      resetTimeout();

      timeoutRef.current = setTimeout(() => {
        if (bannerIndex >= bannerList.length - 1) {
          setBannerIndex(0);

          return;
        }

        setBannerIndex(bannerIndex + 1);
      }, delay);
    }

    return () => {
      resetTimeout();
    };
  }, [bannerIndex]);

  useEffect(() => {
    if (bannerList.length >= 2) {
      resetTimeout();

      timeoutRef.current = setTimeout(() => {
        if (bannerIndex >= bannerList.length - 1) {
          setBannerIndex(0);

          return;
        }

        setBannerIndex(bannerIndex + 1);
      }, delay);
    }

    return () => {
      resetTimeout();
    };
  }, [bannerList]);

  useEffect(() => {
    getList();
  }, []);

  return (
    <Block>
      {bannerList.length ? (
        <div className={cn('banner')}>
          <div className={cn('banner-wrap')}>
            <span className={cn('banner-wrap-title')}>
              {bannerList[bannerIndex].title}
            </span>

            <span className={cn('banner-wrap-subtitle')}>
              {bannerList[bannerIndex].subtitle}
            </span>

            <span className={cn('banner-wrap-text')}>
              {bannerList[bannerIndex].text}
            </span>

            {bannerList[bannerIndex].subtext ? (
              <span className={cn('banner-wrap-text2')}>
                {bannerList[bannerIndex].subtext}
              </span>
            ) : (
              <></>
            )}
          </div>

          <img
            className={cn('banner-img')}
            src={bannerList[bannerIndex].image}
            alt='banner_img'
            draggable={false}
          />

          <div className={cn('banner-slide')}>
            <span className={cn('banner-slide-text')}>
              {bannerIndex + 1}/{bannerList.length}
            </span>

            <div className={cn('banner-slide-wrap')}>
              {bannerList.map((_data, index) => {
                return (
                  <div
                    key={index}
                    style={{ width: `calc(100% / ${bannerList.length})` }}
                    className={cn('banner-slide-wrap-item')}
                    onClick={() => {
                      setBannerIndex(index);
                    }}
                  >
                    <div
                      className={cn('banner-slide-wrap-item-basic', {
                        'banner-slide-wrap-item-select': bannerIndex === index,
                      })}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {exhibition ? (
        <div className={cn('wrap_exhibition')}>
          <span className={cn('wrap_exhibition-title')}>Exhibition</span>

          <div className={cn('wrap_exhibition-contents')}>
            <img
              src={exhibition.thumbnail}
              alt='exhibition_img'
              className={cn('wrap_exhibition-contents-img')}
              draggable={false}
            />

            <div className={cn('wrap_exhibition-contents-wrap')}>
              <span className={cn('wrap_exhibition-contents-wrap-title')}>
                {exhibition.title}
              </span>

              <span className={cn('wrap_exhibition-contents-wrap-location')}>
                {exhibition.location}
              </span>

              <span className={cn('wrap_exhibition-contents-wrap-date')}>
                {`${getUTCDate(String(exhibition.date).split('|')[0])
                  .format('MMM DD YYYY')
                  .toString()} - ${getUTCDate(
                  String(exhibition.date).split('|')[1]
                )
                  .format('MMM DD YYYY')
                  .toString()}`}
              </span>

              <span
                className={cn('wrap_exhibition-contents-wrap-visit')}
                onClick={() => history.push('/exhibition/onview')}
              >
                Visit Exhibition
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {artFairList.length ? (
        <div className={cn('wrap_artfair')}>
          <span className={cn('wrap_artfair-title')}>Art Fair</span>

          <div className={cn('wrap_artfair-contents')}>
            <div className={cn('wrap_artfair-contents-wrap')}>
              {artFairList.map((artfair, index) => {
                if (index > 3) return <></>;

                return (
                  <div
                    key={index}
                    className={cn('wrap_artfair-contents-wrap-item')}
                  >
                    <img
                      className={cn('wrap_artfair-contents-wrap-item-img')}
                      src={artfair.thumbnail}
                      alt='artfair_img'
                      draggable={false}
                      onClick={() => history.push(`/artfair/${artfair.id}`)}
                    />

                    <div className={cn('wrap_artfair-contents-wrap-item-wrap')}>
                      <span
                        className={cn('wrap_artfair-contents-wrap-item-title')}
                        onClick={() => history.push(`/artfair/${artfair.id}`)}
                      >
                        {artfair.title}
                      </span>

                      <div
                        className={cn(
                          'wrap_artfair-contents-wrap-item-artist_wrap'
                        )}
                      >
                        {artfair.artists.map((artist: any, index: number) => {
                          return (
                            <span
                              key={index}
                              className={cn(
                                'wrap_artfair-contents-wrap-item-artist'
                              )}
                            >
                              {artist.name}
                            </span>
                          );
                        })}
                      </div>

                      <span
                        className={cn(
                          'wrap_artfair-contents-wrap-item-continue'
                        )}
                        onClick={() => history.push(`/artfair/${artfair.id}`)}
                      >
                        Continue reading
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={cn('wrap_email')}>
        <div className={cn('wrap_email-text')}>
          Sign up for updates
          <br />
          Join our mailing list for updates about our
          <br />
          artists, exhibitions, events, and more.
        </div>

        <div className={cn('wrap_email-wrap')}>
          <div className={cn('wrap_email-wrap-inputs')}>
            <input
              className={cn('wrap_email-wrap-inputs-input')}
              placeholder='Enter Your First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <input
              className={cn('wrap_email-wrap-inputs-input')}
              placeholder='Enter Your Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <input
            className={cn('wrap_email-wrap-input')}
            placeholder='Enter Your E-mail Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className={cn('wrap_email-wrap-btn')} onClick={postMail}>
            <img
              className={cn('wrap_email-wrap-btn-icon')}
              src={ICON_1}
              alt='mail_icon'
              draggable={false}
            />
          </div>
        </div>

        <div className={cn('wrap_email-text_2')}>
          We will process the personal data you have supplied
          <br />
          in accordance with our privacy policy.
          <br />
          You can unsubscribe or change your preferences at
          <br />
          any time by clicking the link in any emails.
        </div>
      </div>
    </Block>
  );
}

const Block = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .banner {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;

    &-img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      object-position: center;
    }

    &-wrap {
      width: 100%;
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      background-color: ${Color('#000').alpha(0.3).hsl().toString()};
      padding: 20px;

      &-title {
        width: fit-content;
        height: fit-content;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px;
        text-align: center;
      }

      &-subtitle {
        width: fit-content;
        height: fit-content;
        font-size: 15px;
        font-style: italic;
        color: #fff;
        margin-bottom: 20px;
        text-align: center;
      }

      &-text {
        width: fit-content;
        height: fit-content;
        color: #fff;
        margin-bottom: 5px;
        text-align: center;
      }

      &-text2 {
        width: fit-content;
        height: fit-content;
        color: #fff;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    &-slide {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 0 20px;
      margin-top: 10px;

      &-text {
        width: 30px;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        letter-spacing: 2px;
        user-select: none;
        margin-right: 5px;
      }

      &-wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;

        &-item {
          height: fit-content;
          margin: 0 5px;
          padding: 10px 0;
          cursor: pointer;

          &-basic {
            width: 100%;
            height: 5px;
            background-color: #ced4da;
            border-radius: 4px;
          }

          &-select {
            background-color: #212529 !important;
          }
        }
      }
    }
  }

  .wrap_exhibition {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;

    &-title {
      width: fit-content;
      height: fit-content;
      font-family: 'Noto Serif';
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    &-contents {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
      }

      &-wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 18px;
          font-weight: 500;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        &-date,
        &-location {
          width: fit-content;
          height: fit-content;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }

        &-date {
          font-style: italic;
        }

        &-visit {
          width: fit-content;
          height: fit-content;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 1px;
          border-radius: 4px;
          padding: 10px;
          margin-top: 20px;
          color: #fff;
          background-color: #000;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .wrap_artfair {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;

    &-title {
      width: fit-content;
      height: fit-content;
      font-family: 'Noto Serif';
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    &-contents {
      width: 100%;
      height: fit-content;

      &-wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-item {
          width: 100%;
          height: 200px;
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          &-img {
            width: 40%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            margin-right: 10px;
            border-radius: 4px;
          }

          &-wrap {
            width: calc(60% - 10px);
            height: 100%;
            display: flex;
            flex-direction: column;
          }

          &-title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
            user-select: none;
          }

          &-artist_wrap {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }

          &-artist {
            width: fit-content;
            height: fit-content;
            font-family: 'Noto Serif';
            margin: 0 5px 5px 0;
          }

          &-continue {
            width: fit-content;
            height: fit-content;
            margin-top: auto;
            user-select: none;
            font-weight: 500;
            font-style: italic;
            padding: 5px 10px;
            background-color: #ced4da;
            color: #fff;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .wrap_journal {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 100px;

    &-title_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      user-select: none;

      &-title {
        width: 80px;
        height: fit-content;
        font-size: 18px;
        font-weight: 200;
      }

      &-line {
        width: calc(100% - 80px);
        height: 1px;
        background-color: #ddd;
      }
    }

    &-contents {
      width: 100%;
      height: fit-content;
      display: flex;
      overflow: auto;

      &-wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;

        &-more {
          width: 100px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          margin: auto;
          border-radius: 4px;
          border: 1px solid #898989;
          user-select: none;
        }

        &-item {
          width: 250px;
          height: 500px;
          display: flex;
          flex-direction: column;
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }

          &-img {
            width: 100%;
            height: 400px;
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
            margin-bottom: 20px;
          }

          &-wrap {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;

            &-title {
              font-size: 16px;
              font-weight: 500;
              cursor: pointer;
              user-select: none;
              margin-bottom: 10px;
            }

            &-date {
              color: #898989;
              letter-spacing: 2px;
              user-select: none;
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .wrap_email {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &-text {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.8;
      text-align: center;
      margin-bottom: 20px;
    }

    &-text_2 {
      color: #898989;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding: 20px;

      &-inputs {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10px;

        &-input {
          width: 50%;
          height: 50px;
          border: 1px solid #ddd;
          outline: none;
          padding: 0 10px;
          font-size: 13px;
          margin-right: 10px;

          &:last-child {
            margin: 0;
          }
        }
      }

      &-input {
        width: 80%;
        height: 50px;
        border: 1px solid #ddd;
        outline: none;
        padding: 0 10px;
        font-size: 13px;
      }

      &-btn {
        width: 20%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ddd;
        border-left: 0;
        cursor: pointer;

        &-icon {
          width: 30px;
          height: auto;
        }
      }
    }
  }
`;

export default Main;
