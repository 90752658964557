// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("static/fonts/NotoSans-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("static/fonts/NotoSans-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("static/fonts/NotoSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("static/fonts/NotoSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("static/fonts/NotoSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("static/fonts/NotoSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Noto Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,+DAA6D;EAC7D,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,+DAA8D;EAC9D,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,+DAAgE;EAChE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,+DAA+D;EAC/D,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,+DAAiE;EACjE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,+DAA6D;EAC7D,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["@font-face {\n  font-family: 'Noto Sans';\n  src: url('static/fonts/NotoSans-Thin.ttf') format('truetype');\n  font-weight: 100;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Noto Sans';\n  src: url('static/fonts/NotoSans-Light.ttf') format('truetype');\n  font-weight: 200;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Noto Sans';\n  src: url('static/fonts/NotoSans-Regular.ttf') format('truetype');\n  font-weight: 300;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Noto Sans';\n  src: url('static/fonts/NotoSans-Medium.ttf') format('truetype');\n  font-weight: 400;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Noto Sans';\n  src: url('static/fonts/NotoSans-SemiBold.ttf') format('truetype');\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Noto Sans';\n  src: url('static/fonts/NotoSans-Bold.ttf') format('truetype');\n  font-weight: 600;\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
