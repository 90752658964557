import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';

import * as Page from './pages';

import ArtworkProvider from './context/Artwork';
import PublicationsProvider from './context/Publications';

import GlobalStyle from './style/globalStyle';

import { theme } from './constants/theme';

const routes = [
  {
    path: '/',
    exact: true,
    main: () => <Page.Main />,
  },
  {
    path: '/artist',
    exact: true,
    main: () => <Page.Artist />,
  },
  {
    path: '/artist/:id',
    exact: true,
    main: () => <Page.ArtistDetail />,
  },
  {
    path: '/exhibition-upcoming',
    exact: true,
    main: () => <Page.ExhibitionUpcoming />,
  },
  {
    path: '/exhibition-past',
    exact: true,
    main: () => <Page.ExhibitionPast />,
  },
  {
    path: '/exhibition/:id',
    exact: true,
    main: () => <Page.ExhibitionDetail />,
  },
  {
    path: '/online-exhibition',
    exact: true,
    main: () => <Page.OnlineExhibition />,
  },
  {
    path: '/online-exhibition/:id',
    exact: true,
    main: () => <Page.OnlineExhibitionDetail />,
  },
  {
    path: '/artfair',
    exact: true,
    main: () => <Page.ArtFair />,
  },
  {
    path: '/artfair/:id',
    exact: true,
    main: () => <Page.ArtFairDetail />,
  },
  {
    path: '/publications',
    exact: true,
    main: () => <Page.Publications />,
  },
  {
    path: '/about',
    exact: true,
    main: () => <Page.About />,
  },
];

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <PublicationsProvider>
      <ArtworkProvider>
        <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>
          <ThemeProvider theme={theme as DefaultTheme}>
            <GlobalStyle />
            <Router>
              <ScrollToTop />
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.main />}
                  />
                ))}
              </Switch>
            </Router>
          </ThemeProvider>
        </ToastProvider>
      </ArtworkProvider>
    </PublicationsProvider>
  );
}

export default App;
