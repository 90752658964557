import { isBrowser } from 'react-device-detect';

import Navigation from '../components/common/Navigation';

import Component from '../components/main';
import MobileComponent from '../components/mobile_main';

function Main() {
  return (
    <Navigation>{isBrowser ? <Component /> : <MobileComponent />}</Navigation>
  );
}

export default Main;
