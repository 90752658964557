import axios from 'axios';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { getUTCDate } from '../../library/date.lib';
import { useArtwork } from '../../context/Artwork';

export default function OnlineExhibitionDetail() {
  const { id }: any = useParams();
  const history = useHistory();

  const { handleArtwork } = useArtwork();

  const [exhibition, setExhibition] = useState<any>(null);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const getExhibition = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_ADDRESS}/art/online-exhibition/${id}`
      )
      .then(async (response) => {
        setExhibition(response.data.data);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <>
      {exhibition ? (
        <Box>
          <span className={cn('title')}>{exhibition.title}</span>

          <span className={cn('subtitle')}>{`${getUTCDate(
            String(exhibition.date).split('|')[0]
          )
            .format('MMM DD YYYY')
            .toString()} - ${getUTCDate(String(exhibition.date).split('|')[1])
            .format('MMM DD YYYY')
            .toString()}`}</span>

          {exhibition.images.map((img: any, index: number) => {
            return (
              <img
                className={cn('img')}
                key={index}
                src={img.image}
                alt='oe_img'
                draggable={false}
              />
            );
          })}

          <div className={cn('about')}>{handleSentence(exhibition.about)}</div>

          <div className={cn('artwork_list')}>
            {exhibition.artworks.map((artwork: any, index: number) => {
              return (
                <div key={index} className={cn('artwork_list-item')}>
                  <img
                    src={artwork.thumbnail}
                    alt='artwork_img'
                    className={cn('artwork_list-item-img')}
                    draggable={false}
                  />

                  <span className={cn('artwork_list-item-title')}>
                    {artwork.title}, {artwork.year}
                  </span>

                  <span className={cn('artwork_list-item-medium')}>
                    {artwork.medium}
                  </span>

                  <span className={cn('artwork_list-item-size')}>
                    {artwork.size}
                  </span>

                  <div
                    className={cn('artwork_list-item-btn')}
                    onClick={() => handleArtwork(artwork)}
                  >
                    DETAILS
                  </div>
                </div>
              );
            })}
          </div>

          <span className={cn('title2')}>About the Artist</span>

          <div className={cn('artist_list')}>
            {exhibition.artists.map((artist: any, index: number) => {
              return (
                <div key={index} className={cn('artist_list-item')}>
                  <img
                    src={artist.thumbnail}
                    alt='artist_img'
                    className={cn('artist_list-item-img')}
                    draggable={false}
                  />

                  <div className={cn('artist_list-item-wrap')}>
                    <div className={cn('artist_list-item-wrap-about')}>
                      {handleSentence(artist.about)}
                    </div>

                    <span
                      className={cn('artist_list-item-wrap-name')}
                      onClick={() => history.push(`/artist/${artist.id}`)}
                    >
                      {artist.name}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding: 100px 150px;

  .title {
    width: fit-content;
    height: fit-content;
    font-family: 'Rock Salt';
    font-size: 60px;
    font-weight: 500;
    letter-spacing: 3px;
    margin-bottom: 30px;
  }

  .subtitle {
    width: fit-content;
    height: fit-content;
    font-style: italic;
    font-size: 24px;
    font-weight: 400;
    color: #0b08ad;
    margin-bottom: 100px;
  }

  .img {
    width: 55%;
    height: fit-content;
    border: 1px solid #868e96;
    margin-bottom: 50px;

    &:nth-child(2n) {
      margin: 0 0 50px auto;
    }
  }

  .about {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    margin: 100px 0 200px 0;
  }

  .artwork_list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 100px;
    column-gap: 100px;
    margin-bottom: 200px;

    &-item {
      width: 100%;
      height: 550px;
      display: flex;
      flex-direction: column;

      &-img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        object-position: center;
        margin-bottom: 20px;
      }

      &-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      &-medium {
        font-size: 15px;
        margin-bottom: 20px;
      }

      &-size {
        font-size: 15px;
        margin-bottom: auto;
      }

      &-btn {
        width: 100%;
        height: fit-content;
        text-align: center;
        padding: 5px 0;
        font-weight: 400;
        color: #0b08ad;
        transition: all 0.5s ease-in-out;
        border: 1px solid #0b08ad;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: #0b08ad;
          color: #fff;
        }
      }
    }
  }

  .title2 {
    width: 100%;
    height: fit-content;
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 100px;
  }

  .artist_list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-item {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 100px;

      &:last-child {
        margin-bottom: 0;
      }

      &-img {
        width: calc(50% - 50px);
        height: 100%;
        object-fit: cover;
        object-position: center;
        border: 1px solid #868e96;
      }

      &-wrap {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 50px;

        &-about {
          width: 100%;
          height: fit-content;
          font-size: 16px;
          line-height: 1.8;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 12;
          -webkit-box-orient: vertical;
          word-break: keep-all;
        }

        &-name {
          width: fit-content;
          height: fit-content;
          font-family: 'Rock Salt';
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 3px;
          padding-bottom: 5px;
          border-bottom: 1px solid #000;
          cursor: pointer;
          user-select: none;
          margin-top: auto;
        }
      }
    }
  }
`;
