import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function Journal() {
  const history = useHistory();

  const [journalList, setJournalList] = useState<any[]>([]);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const handleJournal = (id: any, type: any) => {
    switch (type) {
      case 'journal':
        history.push(`/journal/${id}`);

        return;

      case 'exhibition':
        history.push(`/exhibition/${id}`);

        return;

      case 'artfair':
        history.push(`/artfair/${id}`);

        return;

      default:
        break;
    }
  };

  const getJournal = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal-fe`)
      .then((response) => {
        setJournalList(response.data.data);
      });
  };

  useEffect(() => {
    getJournal();
  }, []);

  return (
    <Box>
      <div className={cn('journal_contents')}>
        {journalList.map((journal: any, index) => {
          return (
            <div
              key={index}
              className={cn('journal_contents-item')}
              onClick={() => handleJournal(journal.id, journal.type)}
            >
              <span className={cn('journal_contents-item-title')}>
                {journal.title}
              </span>

              <span className={cn('journal_contents-item-about')}>
                {handleSentence(journal.about)}
              </span>

              {journal.image || journal.thumbnail ? (
                <img
                  src={journal.image || journal.thumbnail}
                  alt='journal'
                  className={cn('journal_contents-item-img')}
                  draggable={false}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .journal_contents {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &-item {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 20px 10px;
      border-bottom: 1px solid #898989;
      user-select: none;

      &:last-child {
        border-bottom: 0;
      }

      &-title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      &-about {
        width: 100%;
        height: fit-content;
        line-height: 1.5;
        color: #898989;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        word-break: keep-all;
      }

      &-img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center;
        margin-top: auto;
        margin-bottom: 0;
      }
    }
  }
`;
