import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing:border-box;
    font-size: 13px;
    font-family: 'DM Serif Text';
    font-weight: 300;
    line-height: 1.3;
    color:${(prop) => prop.theme.font};
  }

  #root{
    width:100%;
    min-height:100vh;
    background-color: #f8f9fa;
  }
`;

export default GlobalStyle;
