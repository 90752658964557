import { isBrowser } from 'react-device-detect';

import Navigation from '../components/common/Navigation';

import Component from '../components/publications';
import MobileComponent from '../components/mobile_publications';

function Publications() {
  return (
    <Navigation>{isBrowser ? <Component /> : <MobileComponent />}</Navigation>
  );
}

export default Publications;
