import { ReactChild, useContext, useState, createContext } from 'react';

const PublicationsContext = createContext<{
  publications: any;
  handlePublications: (prop: any) => void;
}>({
  publications: null,
  handlePublications: () => {},
});

export default function PublicationsProvider({
  children,
}: {
  children: ReactChild | ReactChild[];
}) {
  const [publications, setPublications] = useState<any>(null);

  const handlePublications = (prop: any) => {
    setPublications(prop);
  };

  return (
    <PublicationsContext.Provider value={{ publications, handlePublications }}>
      {children}
    </PublicationsContext.Provider>
  );
}

export const usePublications = () => {
  const context = useContext(PublicationsContext);

  return context;
};
