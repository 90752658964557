import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Color from 'color';

import ICON_1 from '../../static/images/location.svg';
import ICON_2 from '../../static/images/calendar.svg';
import { useArtwork } from '../../context/Artwork';
import { getUTCDate } from '../../library/date.lib';

export default function ExhibitionDetail() {
  const { id }: any = useParams();
  const history = useHistory();
  const location = useLocation();

  const { handleArtwork } = useArtwork();

  const [exhibition, setExhibition] = useState<any>(null);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/${id}`)
      .then(async (response) => {
        setExhibition(response.data.data);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <>
      {exhibition ? (
        <Box>
          <div className={cn('btn_wrap')}>
            <span
              className={cn('btn_wrap-btn')}
              onClick={() => history.push('/exhibition/onview')}
              style={{
                backgroundColor: location.pathname.includes('/onview')
                  ? '#000'
                  : '',
                color: location.pathname.includes('/onview') ? '#fff' : '',
              }}
            >
              On View
            </span>

            <span
              className={cn('btn_wrap-btn')}
              onClick={() => history.push('/exhibition-upcoming')}
              style={{
                backgroundColor: location.pathname.includes(
                  '/exhibition-upcoming'
                )
                  ? '#000'
                  : '',
                color: location.pathname.includes('/exhibition-upcoming')
                  ? '#fff'
                  : '',
              }}
            >
              Upcoming
            </span>

            <span
              className={cn('btn_wrap-btn')}
              onClick={() => history.push('/exhibition-past')}
              style={{
                backgroundColor: location.pathname.includes('/exhibition-past')
                  ? '#000'
                  : '',
                color: location.pathname.includes('/exhibition-past')
                  ? '#fff'
                  : '',
              }}
            >
              Past
            </span>
          </div>

          <div className={cn('header_wrap')}>
            <img
              className={cn('header_wrap-img')}
              src={exhibition.thumbnail}
              alt='exhibition_img'
            />

            <div className={cn('header_wrap-title_wrap')}>
              <span className={cn('header_wrap-title_wrap-title')}>
                {exhibition.title}
              </span>

              <span
                className={cn('header_wrap-title_wrap-date')}
              >{`${getUTCDate(String(exhibition.date).split('|')[0])
                .format('MMM DD YYYY')
                .toString()} - ${getUTCDate(
                String(exhibition.date).split('|')[1]
              )
                .format('MMM DD YYYY')
                .toString()}`}</span>
            </div>
          </div>

          <div className={cn('wrap')}>
            <div className={cn('wrap-contents')}>
              <div className={cn('wrap-contents-icon_wrap')}>
                <img
                  src={ICON_1}
                  alt='icon_img'
                  style={{ width: '16px', height: 'auto' }}
                  className={cn('wrap-contents-icon_wrap-icon')}
                  draggable={false}
                />

                <span className={cn('wrap-contents-icon_wrap-text')}>
                  {exhibition.location}
                </span>
              </div>

              <div
                className={cn('wrap-contents-icon_wrap')}
                style={{ marginBottom: '50px' }}
              >
                <img
                  src={ICON_2}
                  alt='icon_img'
                  style={{ width: '18px', height: 'auto' }}
                  className={cn('wrap-contents-icon_wrap-icon')}
                  draggable={false}
                />

                <span
                  className={cn('wrap-contents-icon_wrap-text')}
                >{`${getUTCDate(String(exhibition.date).split('|')[0])
                  .format('MM.DD.YYYY')
                  .toString()} - ${getUTCDate(
                  String(exhibition.date).split('|')[1]
                )
                  .format('MM.DD.YYYY')
                  .toString()}`}</span>
              </div>

              <span className={cn('wrap-contents-title')}>About</span>

              <div className={cn('wrap-contents-about')}>
                {handleSentence(exhibition.about)}
              </div>

              <span className={cn('wrap-contents-title')}>Artists</span>

              {exhibition.artists.map((artist: any, index: number) => {
                return (
                  <span
                    key={index}
                    onClick={() => history.push(`/artist/${artist.id}`)}
                    className={cn('wrap-contents-artist')}
                  >
                    {artist.name}
                  </span>
                );
              })}

              <div className={cn('wrap-contents-img_wrap')}>
                {exhibition.images.length ? (
                  <div className={cn('wrap-contents-img_wrap-list')}>
                    {exhibition.images.map((data: any, index: number) => {
                      return (
                        <img
                          key={index}
                          src={data.image}
                          alt='exhibition_img'
                          className={cn('wrap-contents-img_wrap-list-img')}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <span className={cn('wrap-contents-title')}>Artworks</span>

              <div className={cn('wrap-contents-artworks')}>
                {exhibition.artworks.map((artwork: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={cn('wrap-contents-artworks-item')}
                    >
                      <img
                        className={cn('wrap-contents-artworks-item-img')}
                        src={artwork.thumbnail}
                        alt='artwork_img'
                        draggable={false}
                        onClick={() => handleArtwork(artwork)}
                      />

                      <span
                        className={cn('wrap-contents-artworks-item-title')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.title}
                      </span>

                      <span
                        className={cn('wrap-contents-artworks-item-year')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.year}
                      </span>

                      <span
                        className={cn('wrap-contents-artworks-item-medium')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.medium}
                      </span>

                      <span
                        className={cn('wrap-contents-artworks-item-size')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.size}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {exhibition.video_link ? (
            <div className={cn('video_wrap')}>
              <div className={cn('video_wrap-line')} />

              <span className={cn('video_wrap-title')}>Exhibition Film</span>

              <div className={cn('video_wrap-wrap')}>
                <video
                  className={cn('video_wrap-wrap-frame')}
                  src={`${exhibition.video_link}#t=0.3`}
                  preload='metadata'
                  controls
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .btn_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 30px 0;

    &-btn {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      margin-right: 20px;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid #000;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .header_wrap {
    width: 100%;
    height: 300px;
    position: relative;
    margin-bottom: 30px;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-title_wrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${Color('#000').alpha(0.3).hsl().toString()};
      padding: 20px;

      &-title {
        letter-spacing: 1px;
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 20px;
      }

      &-date {
        letter-spacing: 1px;
        font-style: italic;
        font-size: 16px;
        font-weight: 200;
        color: #fff;
      }
    }
  }

  .wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    &-contents {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;

        &-icon {
          margin-right: 10px;
        }
      }

      &-title {
        width: fit-content;
        height: fit-content;
        font-family: 'Noto Serif';
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 30px;
      }

      &-about {
        text-align: center;
        letter-spacing: 1px;
        line-height: 1.8;
        margin-bottom: 50px;
      }

      &-artist {
        width: fit-content;
        height: fit-content;
        letter-spacing: 1px;
        margin-bottom: 20px;
        user-select: none;
      }

      &-img_wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        margin: 30px 0 50px 0;
        overflow: auto;
        user-select: none;

        &-list {
          width: fit-content;
          height: fit-content;
          display: flex;
          flex-direction: row;

          &-img {
            width: 250px;
            height: 300px;
            object-fit: contain;
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &-artworks {
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        row-gap: 30px;
        column-gap: 20px;
        margin-bottom: 50px;

        &-item {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: center;
          flex-direction: column;

          &-img {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
          }

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            margin-top: auto;
            margin-bottom: 10px;
            user-select: none;
          }

          &-year {
            width: fit-content;
            height: fit-content;
            font-weight: 400;
            margin-bottom: 5px;
            user-select: none;
          }

          &-medium {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
            text-align: center;
            margin-bottom: 5px;
            user-select: none;
          }

          &-size {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
            text-align: center;
            user-select: none;
          }
        }
      }
    }
  }

  .video_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin-bottom: 50px;
    }

    &-title {
      width: 100%;
      height: fit-content;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 2px;
      margin-bottom: 30px;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(0, 0, 0, 1) 5%,
        rgba(0, 0, 0, 1) 95%,
        rgba(255, 255, 255, 1) 100%
      ); */

      &-frame {
        width: 100%;
        height: fit-content;
        border: 0;
      }
    }
  }
`;
