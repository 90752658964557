import styled from 'styled-components';
import cn from 'classnames';
import { usePublications } from '../../context/Publications';
import { Fragment, useState } from 'react';
import axios from 'axios';
import useInputs from '../../hooks/useInputs';

import ICON from '../../static/images/close.svg';
import { useToasts } from 'react-toast-notifications';
import Color from 'color';
import { isBrowser } from 'react-device-detect';

export default function PublicationsModal() {
  const { addToast } = useToasts();

  const { publications, handlePublications } = usePublications();

  const [isInquiry, setIsInquiry] = useState(false);

  const [inputs, onChange, reset] = useInputs({
    first_name: '',
    last_name: '',
    email: '',
  });

  const handleClose = () => {
    reset();
    handlePublications(null);
    setIsInquiry(false);
  };

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const postInquire = () => {
    if (
      inputs.email === '' ||
      inputs.first_name === '' ||
      inputs.last_name === ''
    ) {
      addToast('Please enter the information required for your inquiry.', {
        appearance: 'warning',
      });

      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER_ADDRESS}/contact/publications-inquire`,
        {
          ...inputs,
          publications_id: publications.id,
        }
      )
      .then(() => {
        addToast('Your inquiry has been successfully received.', {
          appearance: 'success',
        });

        reset();
        handlePublications(null);
        setIsInquiry(false);
      });
  };

  return (
    <>
      {publications ? (
        <>
          <Background />

          {isBrowser ? (
            <Box>
              <img
                className={cn('icon')}
                src={ICON}
                alt='close_icon'
                draggable={false}
                onClick={handleClose}
              />

              <img
                className={cn('publications_img')}
                src={publications.image}
                alt='publications_img'
                draggable={false}
                title='View image in detail'
                onClick={() => window.open(publications.image, '_blank')}
              />

              <div className={cn('contents')}>
                <div className={cn('contents-wrap')}>
                  <span className={cn('contents-title')}>
                    {publications.title}
                  </span>

                  <span className={cn('contents-sub_title')}>
                    {publications.sub_title}
                  </span>

                  <span className={cn('contents-text')}>
                    {handleSentence(publications.text)}
                  </span>

                  {isInquiry ? (
                    <>
                      <div className={cn('contents-line')} />

                      <span className={cn('contents-input_title')}>
                        First Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='first_name'
                        value={inputs.first_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        Last Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='last_name'
                        value={inputs.last_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        E-mail *
                      </span>

                      <input
                        className={cn('contents-input')}
                        style={{ marginBottom: 0 }}
                        name='email'
                        value={inputs.email}
                        onChange={onChange}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <button
                    className={cn('contents-btn')}
                    style={{ marginTop: isInquiry ? '50px' : 'auto' }}
                    onClick={() => {
                      if (isInquiry) {
                        postInquire();

                        return;
                      }

                      setIsInquiry(true);
                    }}
                  >
                    {!isInquiry ? 'Inquire' : 'Send Inquire'}
                  </button>
                </div>
              </div>
            </Box>
          ) : (
            <MobileBox>
              <img
                className={cn('icon')}
                src={ICON}
                alt='close_icon'
                draggable={false}
                onClick={handleClose}
              />

              <img
                className={cn('publications_img')}
                onClick={() => window.open(publications.image, '_blank')}
                src={publications.image}
                alt='publications_img'
                draggable={false}
              />

              <div className={cn('contents')}>
                <div className={cn('contents-wrap')}>
                  <span className={cn('contents-title')}>
                    {publications.title}
                  </span>

                  <span className={cn('contents-sub_title')}>
                    {publications.sub_title}
                  </span>

                  <span className={cn('contents-text')}>
                    {handleSentence(publications.text)}
                  </span>

                  {isInquiry ? (
                    <>
                      <div className={cn('contents-line')} />

                      <span className={cn('contents-input_title')}>
                        First Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='first_name'
                        value={inputs.first_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        Last Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='last_name'
                        value={inputs.last_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        E-mail *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='email'
                        style={{ marginBottom: 0 }}
                        value={inputs.email}
                        onChange={onChange}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <button
                    className={cn('contents-btn')}
                    onClick={() => {
                      if (isInquiry) {
                        postInquire();

                        return;
                      }

                      setIsInquiry(true);
                    }}
                  >
                    {!isInquiry ? 'Inquire' : 'Send Inquire'}
                  </button>
                </div>
              </div>
            </MobileBox>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${Color('#000').alpha(0.5).hsl().toString()};
`;

const Box = styled.div`
  width: 60vw;
  height: 70vh;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 4;
  overflow: hidden;

  .icon {
    width: 16px;
    height: auto;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  .publications_img {
    width: 60%;
    height: 100%;
    padding: 20px;
    object-fit: contain;
    margin-bottom: 30px;
    border-right: 1px solid #ddd;
    cursor: pointer;
  }

  .contents {
    width: 40%;
    height: 100%;
    display: flex;
    padding: 50px;
    overflow: auto;

    &-wrap {
      width: 100%;
      height: fit-content;
      min-height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin: 20px 0;
    }

    &-title {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    &-sub_title {
      font-family: 'Noto Serif';
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #707070;
      margin-bottom: 20px;
    }

    &-text {
      letter-spacing: 1px;
      font-size: 15px;
      font-weight: 200;
      margin-bottom: 10px;
    }

    &-input_title {
      margin-bottom: 10px;
    }

    &-input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      outline: none;
      border: 1px solid #d9d9d9;
      margin-bottom: 20px;
    }

    &-btn {
      width: 250px;
      height: fit-content;
      border: 0;
      background-color: #000;
      color: #fff;
      padding: 20px 50px;
      font-size: 15px;
      letter-spacing: 1px;
      cursor: pointer;
      margin: 0 auto;
    }
  }
`;

const MobileBox = styled.div`
  width: 90vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 4;
  overflow: auto;
  padding: 20px;

  .icon {
    width: 14px;
    height: auto;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .publications_img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 20px;
  }

  .contents {
    width: 100%;
    height: fit-content;
    display: flex;

    &-wrap {
      width: 100%;
      height: fit-content;
      min-height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin: 20px 0;
    }

    &-title {
      font-size: 17px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    &-sub_title {
      font-family: 'Noto Serif';
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #707070;
      margin-bottom: 20px;
    }

    &-text {
      font-weight: 200;
      margin-bottom: 10px;
    }

    &-input_title {
      margin-bottom: 10px;
    }

    &-input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      outline: none;
      border: 1px solid #d9d9d9;
      margin-bottom: 20px;
    }

    &-btn {
      width: 200px;
      height: fit-content;
      border: 0;
      background-color: #000;
      color: #fff;
      font-weight: 400;
      padding: 20px 30px;
      letter-spacing: 1px;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
`;
