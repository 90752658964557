import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ICON_1 from '../../static/images/location.svg';
import ICON_2 from '../../static/images/calendar.svg';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useArtwork } from '../../context/Artwork';
import { getUTCDate } from '../../library/date.lib';

export default function ExhibitionDetail() {
  const { id }: any = useParams();
  const history = useHistory();
  const location = useLocation();

  const { handleArtwork } = useArtwork();

  const [exhibition, setExhibition] = useState<any>(null);
  const [imgIndex, setImgIndex] = useState(0);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/${id}`)
      .then(async (response) => {
        setExhibition(response.data.data);
      });
  };

  useEffect(() => {
    getExhibition();
  }, [location.pathname]);

  return (
    <>
      {exhibition ? (
        <Box>
          <div className={cn('btn_wrap')}>
            <span
              className={cn('btn_wrap-btn')}
              onClick={() => history.push('/exhibition/onview')}
              style={{
                backgroundColor: location.pathname.includes('/onview')
                  ? '#000'
                  : '',
                color: location.pathname.includes('/onview') ? '#fff' : '',
              }}
            >
              On View
            </span>

            <span
              className={cn('btn_wrap-btn')}
              onClick={() => history.push('/exhibition-upcoming')}
              style={{
                backgroundColor: location.pathname.includes(
                  '/exhibition-upcoming'
                )
                  ? '#000'
                  : '',
                color: location.pathname.includes('/exhibition-upcoming')
                  ? '#fff'
                  : '',
              }}
            >
              Upcoming
            </span>

            <span
              className={cn('btn_wrap-btn')}
              onClick={() => history.push('/exhibition-past')}
              style={{
                backgroundColor: location.pathname.includes('/exhibition-past')
                  ? '#000'
                  : '',
                color: location.pathname.includes('/exhibition-past')
                  ? '#fff'
                  : '',
              }}
            >
              Past
            </span>
          </div>

          <div className={cn('header_wrap')}>
            <img
              className={cn('header_wrap-img')}
              src={exhibition.thumbnail}
              alt='exhibition_img'
              draggable={false}
            />

            <div className={cn('header_wrap-title_wrap')}>
              <span className={cn('header_wrap-title_wrap-title')}>
                {exhibition.title}
              </span>

              <span
                className={cn('header_wrap-title_wrap-date')}
              >{`${getUTCDate(String(exhibition.date).split('|')[0])
                .format('MMM DD YYYY')
                .toString()} - ${getUTCDate(
                String(exhibition.date).split('|')[1]
              )
                .format('MMM DD YYYY')
                .toString()}`}</span>
            </div>
          </div>

          <div className={cn('wrap')}>
            <div className={cn('wrap-contents')}>
              <div className={cn('wrap-contents-icon_wrap_p')}>
                <div className={cn('wrap-contents-icon_wrap')}>
                  <img
                    src={ICON_1}
                    alt='icon_img'
                    style={{ width: '16px', height: 'auto' }}
                    className={cn('wrap-contents-icon_wrap-icon')}
                    draggable={false}
                  />

                  <span className={cn('wrap-contents-icon_wrap-text')}>
                    {exhibition.location}
                  </span>
                </div>

                <div className={cn('wrap-contents-icon_wrap')}>
                  <img
                    src={ICON_2}
                    alt='icon_img'
                    style={{ width: '18px', height: 'auto' }}
                    className={cn('wrap-contents-icon_wrap-icon')}
                    draggable={false}
                  />

                  <span
                    className={cn('wrap-contents-icon_wrap-text')}
                  >{`${getUTCDate(String(exhibition.date).split('|')[0])
                    .format('MM.DD.YYYY')
                    .toString()} - ${getUTCDate(
                    String(exhibition.date).split('|')[1]
                  )
                    .format('MM.DD.YYYY')
                    .toString()}`}</span>
                </div>
              </div>

              <span className={cn('wrap-contents-title')}>About</span>

              <div className={cn('wrap-contents-about')}>
                {handleSentence(exhibition.about)}
              </div>

              <span className={cn('wrap-contents-title')}>Artists</span>

              {exhibition.artists.map((artist: any, index: number) => {
                return (
                  <span
                    key={index}
                    onClick={() => history.push(`/artist/${artist.id}`)}
                    className={cn('wrap-contents-artist')}
                  >
                    {artist.name}
                  </span>
                );
              })}

              {exhibition.images.length ? (
                <div className={cn('wrap-contents-img_wrap')}>
                  <NavigateBefore
                    style={{ fill: imgIndex === 0 ? '#adb5bd' : '#000' }}
                    className={cn('wrap-contents-img_wrap-icon')}
                    onClick={() => {
                      if (imgIndex === 0) return;

                      setImgIndex(imgIndex - 1);
                    }}
                  />

                  <img
                    src={exhibition.images[imgIndex].image}
                    alt='exhibition_img'
                    className={cn('wrap-contents-img_wrap-img')}
                  />

                  <NavigateNext
                    style={{
                      fill:
                        imgIndex === exhibition.images.length - 1
                          ? '#adb5bd'
                          : '#000',
                    }}
                    className={cn('wrap-contents-img_wrap-icon')}
                    onClick={() => {
                      if (imgIndex === exhibition.images.length - 1) return;

                      setImgIndex(imgIndex + 1);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              <span className={cn('wrap-contents-title')}>Artworks</span>

              <div className={cn('wrap-contents-artworks')}>
                {exhibition.artworks.map((artwork: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={cn('wrap-contents-artworks-item')}
                    >
                      <img
                        className={cn('wrap-contents-artworks-item-img')}
                        src={artwork.thumbnail}
                        alt='artwork_img'
                        draggable={false}
                        onClick={() => handleArtwork(artwork)}
                      />

                      <span
                        className={cn('wrap-contents-artworks-item-title')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.title}
                      </span>

                      <span
                        className={cn('wrap-contents-artworks-item-year')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.year}
                      </span>

                      <span
                        className={cn('wrap-contents-artworks-item-medium')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.medium}
                      </span>

                      <span
                        className={cn('wrap-contents-artworks-item-size')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.size}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {exhibition.video_link ? (
            <div className={cn('video_wrap')}>
              <span className={cn('video_wrap-title')}>Exhibition Film</span>

              <div className={cn('video_wrap-wrap')}>
                <video
                  className={cn('video_wrap-wrap-frame')}
                  src={`${exhibition.video_link}#t=0.3`}
                  preload='metadata'
                  controls
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 60px;
  padding: 100px 150px;

  .btn_wrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    &-btn {
      width: fit-content;
      height: fit-content;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      user-select: none;
      margin-right: 50px;
      border: 1px solid #000;
      padding: 10px 20px;
      margin-bottom: 100px;
      border-radius: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .header_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;

    &-img {
      width: 80%;
      height: auto;
      object-fit: cover;
      object-position: center;
      margin-bottom: 50px;
      border: 1px solid #dee2e6;
    }

    &-title_wrap {
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-title {
        font-size: 50px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      &-date {
        font-size: 18px;
        font-weight: 200;
      }
    }
  }

  .wrap {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-contents {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-icon_wrap_p {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        margin-bottom: 100px;
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        &-icon {
          margin-right: 20px;
        }

        &-text {
          font-size: 15px;
        }
      }

      &-title {
        width: fit-content;
        height: fit-content;
        font-size: 24px;
        font-weight: 500;
        font-family: 'Noto Serif';
        letter-spacing: 2px;
        margin-bottom: 50px;
      }

      &-about {
        width: 80%;
        letter-spacing: 1px;
        line-height: 1.6;
        margin-bottom: 100px;
        text-align: center;
      }

      &-artist {
        width: fit-content;
        height: fit-content;
        letter-spacing: 2px;
        margin-bottom: 20px;
        user-select: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      &-img_wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-top: 60px;
        margin-bottom: 100px;
        user-select: none;

        &-icon {
          width: 50px;
          height: auto;
          cursor: pointer;
        }

        &-img {
          width: 80%;
          height: 900px;
          object-fit: contain;
        }
      }

      &-artworks {
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        row-gap: 50px;
        column-gap: 100px;

        &-item {
          width: 100%;
          height: 600px;
          display: flex;
          align-items: center;
          flex-direction: column;

          &-img {
            width: 100%;
            height: 400px;
            object-fit: contain;
            object-position: bottom;
            cursor: pointer;
          }

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 16px;
            font-weight: 500;
            margin-top: auto;
            margin-bottom: 20px;
            cursor: pointer;
            user-select: none;
          }

          &-year {
            width: fit-content;
            height: fit-content;
            margin-bottom: 5px;
            cursor: pointer;
            user-select: none;
          }

          &-medium {
            width: fit-content;
            height: fit-content;
            margin-bottom: 5px;
            cursor: pointer;
            user-select: none;
          }

          &-size {
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }

  .video_wrap {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin-bottom: 100px;
    }

    &-title {
      width: fit-content;
      height: fit-content;
      font-size: 24px;
      font-weight: 500;
      font-family: 'Noto Serif';
      letter-spacing: 2px;
      margin-bottom: 50px;
    }

    &-wrap {
      width: 80%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(0, 0, 0, 1) 5%,
        rgba(0, 0, 0, 1) 95%,
        rgba(255, 255, 255, 1) 100%
      ); */

      &-frame {
        width: 100%;
        height: fit-content;
        border: 0;
      }
    }
  }
`;
