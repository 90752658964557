import styled from 'styled-components';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getUTCDate } from '../../library/date.lib';
import Color from 'color';

export default function ExhibitionPast() {
  const history = useHistory();
  const location = useLocation();

  const [exhibtions, setExhibitions] = useState<any[]>([]);

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/up`)
      .then(async (response) => {
        setExhibitions(response.data.data.past);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <Box>
      <div className={cn('btn_wrap')}>
        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition/onview')}
          style={{
            backgroundColor: location.pathname.includes('/onview')
              ? '#000'
              : '',
            color: location.pathname.includes('/onview') ? '#fff' : '',
          }}
        >
          On View
        </span>

        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition-upcoming')}
          style={{
            backgroundColor: location.pathname.includes('/exhibition-upcoming')
              ? '#000'
              : '',
            color: location.pathname.includes('/exhibition-upcoming')
              ? '#fff'
              : '',
          }}
        >
          Upcoming
        </span>

        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition-past')}
          style={{
            backgroundColor: location.pathname.includes('/exhibition-past')
              ? '#000'
              : '',
            color: location.pathname.includes('/exhibition-past') ? '#fff' : '',
          }}
        >
          Past
        </span>
      </div>

      {exhibtions.length ? (
        <div className={cn('list')}>
          {exhibtions.map((exhibition, index) => {
            return (
              <div
                key={index}
                className={cn('list-item')}
                onClick={() => history.push(`/exhibition/${exhibition.id}`)}
              >
                <img
                  src={exhibition.thumbnail}
                  alt='exhibition_img'
                  className={cn('list-item-img')}
                  draggable={false}
                />

                <div className={cn('list-item-wrap')}>
                  <span className={cn('list-item-wrap-title')}>
                    {exhibition.title}
                  </span>

                  <span className={cn('list-item-wrap-date')}>{`${getUTCDate(
                    String(exhibition.date).split('|')[0]
                  )
                    .format('MMM DD YYYY')
                    .toString()} - ${getUTCDate(
                    String(exhibition.date).split('|')[1]
                  )
                    .format('MMM DD YYYY')
                    .toString()}`}</span>

                  <span className={cn('list-item-wrap-location')}>
                    {exhibition.location}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <span className={cn('empty')}>Preparing for an exhibition</span>
      )}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  padding: 100px 150px;

  .empty {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 50px;
    font-size: 28px;
    font-style: italic;
    font-weight: 400;
    color: #fff;
    background-color: #ced4da;
    padding: 10px 20px;
    border-radius: 4px;
    letter-spacing: 1px;
    user-select: none;
  }

  .btn_wrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    &-btn {
      width: fit-content;
      height: fit-content;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      user-select: none;
      margin-right: 50px;
      border: 1px solid #000;
      padding: 10px 20px;
      margin-bottom: 100px;
      border-radius: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 50px;
    column-gap: 50px;

    &-item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      &-img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;
      }

      &-wrap {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${Color('#000').alpha(0.5).hsl().toString()};
        cursor: pointer;

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 30px;
          user-select: none;
          color: #fff;
        }

        &-date {
          width: fit-content;
          height: fit-content;
          color: #ced4da;
          letter-spacing: 1px;
          margin-bottom: 10px;
          user-select: none;
        }

        &-location {
          width: fit-content;
          height: fit-content;
          color: #ced4da;
          letter-spacing: 1px;
          user-select: none;
        }
      }
    }
  }
`;
