import { isBrowser } from 'react-device-detect';
import Navigation from '../components/common/Navigation';

import Component from '../components/artist/Detail';
import MobileComponent from '../components/mobile_artist/Detail';

function ArtistDetail() {
  return (
    <Navigation>{isBrowser ? <Component /> : <MobileComponent />}</Navigation>
  );
}

export default ArtistDetail;
