import { useState, useCallback, ChangeEvent } from 'react';

function useInputs<T>(initialForm: T) {
  const [form, setForm] = useState(initialForm);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setForm((form) => ({ ...form, [name]: value }));
  }, []);

  const reset = useCallback(
    (resetForm?: T) => setForm(resetForm || initialForm),
    [initialForm]
  );

  return [form as T, onChange, reset] as [
    T,
    (event: ChangeEvent<HTMLInputElement>) => void,
    (resetForm?: T) => void
  ];
}

export default useInputs;
