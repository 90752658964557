export { default as Main } from './Main';
export { default as Artist } from './Artist';
export { default as ArtistDetail } from './ArtistDetail';
export { default as ExhibitionUpcoming } from './ExhibitionUpcoming';
export { default as ExhibitionPast } from './ExhibitionPast';
export { default as ExhibitionDetail } from './ExhibitionDetail';
export { default as OnlineExhibition } from './OnlineExhibition';
export { default as OnlineExhibitionDetail } from './OnlineExhibitionDetail';
export { default as ArtFair } from './ArtFair';
export { default as ArtFairDetail } from './ArtFairDetail';
export { default as Publications } from './Publications';
export { default as Journal } from './Journal';
export { default as JournalDetail } from './JournalDetail';
export { default as About } from './About';
