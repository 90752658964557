import axios from 'axios';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUTCDate } from '../../library/date.lib';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

export default function OnlineExhibition() {
  const history = useHistory();

  const [exhibitions, setExhibitions] = useState<any[]>([]);
  const [selectExhibition, setSelectExhibition] = useState<any>(null);

  const handleDate = (eb: any) => {
    const currentDate = dayjs().utc();

    const days = String(eb.date).split('|');

    const startDate = dayjs.utc(days[0]);
    const endDate = dayjs.utc(days[1]);

    if (currentDate >= startDate && currentDate <= endDate) return true;

    return false;
  };

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/online-exhibition/cu`)
      .then(async (response) => {
        setExhibitions(response.data.data);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <Box>
      <span className={cn('title')}>DIVE IN TO OUR</span>
      <span className={cn('title')} style={{ marginBottom: '50px' }}>
        ONLINE EXHIBITION
      </span>

      <div className={cn('exhibition_list')}>
        {exhibitions.map((data: any, index) => {
          return (
            <div key={index} className={cn('exhibition_list-item')}>
              <span
                className={cn('exhibition_list-item-title')}
                onClick={() => {
                  if (selectExhibition && selectExhibition.id === data.id) {
                    setSelectExhibition(null);
                  } else {
                    setSelectExhibition(data);
                  }
                }}
              >
                {data.title}
              </span>

              <span
                className={cn('exhibition_list-item-date')}
                onClick={() => {
                  if (selectExhibition && selectExhibition.id === data.id) {
                    setSelectExhibition(null);
                  } else {
                    setSelectExhibition(data);
                  }
                }}
                style={{
                  color: handleDate(data) ? '#FF0000' : '',
                  fontWeight: handleDate(data) ? '600' : '',
                }}
              >
                {`${getUTCDate(String(data.date).split('|')[0])
                  .format('MMM DD YYYY')
                  .toString()} - ${getUTCDate(String(data.date).split('|')[1])
                  .format('MMM DD YYYY')
                  .toString()}`}
              </span>

              {selectExhibition && selectExhibition.id === data.id ? (
                <div className={cn('exhibition_list-item-wrap')}>
                  <img
                    src={data.thumbnail}
                    alt='thumbnail'
                    className={cn('exhibition_list-item-wrap-img')}
                    draggable={false}
                    onClick={() => {
                      history.push(`/online-exhibition/${selectExhibition.id}`);
                    }}
                  />

                  {selectExhibition.artists.map((sdata: any, index: number) => {
                    return (
                      <span
                        key={index}
                        className={cn('exhibition_list-item-wrap-artist')}
                        onClick={() => history.push(`/artist/${sdata.id}`)}
                      >
                        #{sdata.name}
                      </span>
                    );
                  })}

                  <div className={cn('exhibition_list-item-wrap-wrap2')}>
                    <span
                      className={cn('exhibition_list-item-wrap-wrap2-text')}
                      onClick={() => {
                        history.push(
                          `/online-exhibition/${selectExhibition.id}`
                        );
                      }}
                    >
                      DIVE NOW
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 50px 20px;

  .title {
    width: 100%;
    height: fit-content;
    text-align: center;
    letter-spacing: 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.8;
  }

  .exhibition_list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-item {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 20px 0;
      border-top: 1px solid #000;

      &:last-child {
        border-bottom: 1px solid #000;
      }

      &-title {
        width: fit-content;
        height: fit-content;
        font-family: 'Rock Salt';
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 3px;
        cursor: pointer;
        user-select: none;
      }

      &-date {
        width: fit-content;
        height: fit-content;
        font-size: 12px;
        font-weight: 400;
        user-select: none;
        margin-top: 10px;
      }

      &-wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 30px 10px 0px 10px;

        &-img {
          width: 100%;
          height: 200px;
          border-radius: 30px;
          margin-bottom: 30px;
          object-fit: cover;
          object-position: center;
          cursor: pointer;
        }

        &-artist {
          width: fit-content;
          height: fit-content;
          font-size: 13px;
          font-weight: 500;
          font-style: italic;
          margin: 0 15px;
          padding: 10px 0;
          user-select: none;
          text-decoration: underline;
        }

        &-wrap2 {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin-top: 30px;

          &-text {
            width: auto;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px;
            font-family: 'Rock Salt';
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 3px;
            color: #f5f2eb;
            background: radial-gradient(
              ellipse,
              rgba(11, 8, 173) 0%,
              rgb(245, 242, 235) 60%
            );
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
`;
