import { ReactChild, useContext, useState, createContext } from 'react';

const ArtworkContext = createContext<{
  artwork: any;
  handleArtwork: (prop: any) => void;
}>({
  artwork: null,
  handleArtwork: () => {},
});

export default function ArtworkProvider({
  children,
}: {
  children: ReactChild | ReactChild[];
}) {
  const [artwork, setArtwork] = useState<any>(null);

  const handleArtwork = (prop: any) => {
    setArtwork(prop);
  };

  return (
    <ArtworkContext.Provider value={{ artwork, handleArtwork }}>
      {children}
    </ArtworkContext.Provider>
  );
}

export const useArtwork = () => {
  const context = useContext(ArtworkContext);

  return context;
};
