import styled from 'styled-components';
import cn from 'classnames';
import { useArtwork } from '../../context/Artwork';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useInputs from '../../hooks/useInputs';

import ICON from '../../static/images/close.svg';
import { useToasts } from 'react-toast-notifications';
import Color from 'color';
import { isBrowser } from 'react-device-detect';

export default function Artwork() {
  const { addToast } = useToasts();

  const { artwork, handleArtwork } = useArtwork();

  const [selectArtwork, setSelectArtwork] = useState<any>(null);
  const [imgIndex, setImgIndex] = useState(0);

  const [isInquiry, setIsInquiry] = useState(false);

  const [inputs, onChange, reset] = useInputs({
    first_name: '',
    last_name: '',
    email: '',
  });

  const [message, setMessage] = useState('');

  const handleClose = () => {
    reset();
    setMessage('');
    handleArtwork(null);
    setIsInquiry(false);
  };

  const postInquire = () => {
    if (
      inputs.email === '' ||
      inputs.first_name === '' ||
      inputs.last_name === ''
    ) {
      addToast('Please enter the information required for your inquiry.', {
        appearance: 'warning',
      });

      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/inquire`, {
        ...inputs,
        message,
        artwork_id: selectArtwork.id,
      })
      .then(() => {
        addToast('Your inquiry has been successfully received.', {
          appearance: 'success',
        });

        reset();
        setMessage('');
        handleArtwork(null);
        setIsInquiry(false);
      });
  };

  const getArtwork = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork/${artwork.id}`)
      .then((response) => {
        setSelectArtwork(response.data.data);
      });
  };

  useEffect(() => {
    getArtwork();
  }, []);

  return (
    <>
      {selectArtwork ? (
        <>
          <Background />

          {isBrowser ? (
            <Box>
              <img
                className={cn('icon')}
                src={ICON}
                alt='close_icon'
                draggable={false}
                onClick={handleClose}
              />

              <div className={cn('img_wrap')}>
                <img
                  className={cn('img_wrap-img')}
                  src={selectArtwork.images[imgIndex].image}
                  alt='artwork_img'
                  draggable={false}
                  title='View image in detail'
                  onClick={() =>
                    window.open(selectArtwork.images[imgIndex].image, '_blank')
                  }
                />

                <div className={cn('img_wrap-wrap')}>
                  {selectArtwork.images.map((image: any, index: number) => {
                    if (index > 4) return <></>;

                    return (
                      <img
                        key={index}
                        className={cn('img_wrap-wrap-img')}
                        onClick={() => setImgIndex(index)}
                        src={image.image}
                        alt='artwork_img'
                        draggable={false}
                      />
                    );
                  })}
                </div>
              </div>

              <div className={cn('contents')}>
                <div className={cn('contents-wrap')}>
                  <span className={cn('contents-artist')}>
                    {selectArtwork.artist.name}
                  </span>

                  <span className={cn('contents-title')}>
                    {selectArtwork.title}
                  </span>

                  <span className={cn('contents-year')}>
                    {selectArtwork.year}
                  </span>

                  <div className={cn('contents-line')} />

                  <span className={cn('contents-medium')}>
                    {selectArtwork.medium}
                  </span>

                  <span className={cn('contents-size')}>
                    {selectArtwork.size}
                  </span>

                  {isInquiry ? (
                    <>
                      <div className={cn('contents-line')} />

                      <span className={cn('contents-input_title')}>
                        First Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='first_name'
                        value={inputs.first_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        Last Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='last_name'
                        value={inputs.last_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        E-mail *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='email'
                        value={inputs.email}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        Message
                      </span>

                      <textarea
                        className={cn('contents-textarea')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <button
                    className={cn('contents-btn')}
                    style={{ marginTop: isInquiry ? '50px' : 'auto' }}
                    onClick={() => {
                      if (isInquiry) {
                        postInquire();

                        return;
                      }

                      setIsInquiry(true);
                    }}
                  >
                    {!isInquiry ? 'Inquire' : 'Send Inquire'}
                  </button>
                </div>
              </div>
            </Box>
          ) : (
            <MobileBox>
              <img
                className={cn('icon')}
                src={ICON}
                alt='close_icon'
                draggable={false}
                onClick={handleClose}
              />

              <div className={cn('img_wrap')}>
                <div className={cn('img_wrap-wrap')}>
                  {selectArtwork.images.map((image: any, index: number) => {
                    return (
                      <img
                        key={index}
                        className={cn('img_wrap-wrap-img')}
                        onClick={() => window.open(image.image, '_blank')}
                        src={image.image}
                        alt='artwork_img'
                        draggable={false}
                      />
                    );
                  })}
                </div>
              </div>

              <div className={cn('contents')}>
                <div className={cn('contents-wrap')}>
                  <span className={cn('contents-artist')}>
                    {selectArtwork.artist.name}
                  </span>

                  <span className={cn('contents-title')}>
                    {selectArtwork.title}
                  </span>

                  <span className={cn('contents-year')}>
                    {selectArtwork.year}
                  </span>

                  <div className={cn('contents-line')} />

                  <span className={cn('contents-medium')}>
                    {selectArtwork.medium}
                  </span>

                  <span className={cn('contents-size')}>
                    {selectArtwork.size}
                  </span>

                  {isInquiry ? (
                    <>
                      <div className={cn('contents-line')} />

                      <span className={cn('contents-input_title')}>
                        First Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='first_name'
                        value={inputs.first_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        Last Name *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='last_name'
                        value={inputs.last_name}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        E-mail *
                      </span>

                      <input
                        className={cn('contents-input')}
                        name='email'
                        value={inputs.email}
                        onChange={onChange}
                      />

                      <span className={cn('contents-input_title')}>
                        Message
                      </span>

                      <textarea
                        className={cn('contents-textarea')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <button
                    className={cn('contents-btn')}
                    onClick={() => {
                      if (isInquiry) {
                        postInquire();

                        return;
                      }

                      setIsInquiry(true);
                    }}
                  >
                    {!isInquiry ? 'Inquire' : 'Send Inquire'}
                  </button>
                </div>
              </div>
            </MobileBox>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${Color('#000').alpha(0.5).hsl().toString()};
`;

const Box = styled.div`
  width: 60vw;
  height: 70vh;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 4;
  overflow: hidden;

  .icon {
    width: 16px;
    height: auto;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  .img_wrap {
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 50px;
    border-right: 1px solid #ddd;

    &-img {
      width: 100%;
      height: 60%;
      object-fit: contain;
      margin-bottom: 30px;
      border: 1px solid #ddd;
      cursor: pointer;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &-img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border: 1px solid #ddd;
        margin-right: 30px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .contents {
    width: 35%;
    height: 100%;
    display: flex;
    padding: 50px;
    overflow: auto;

    &-wrap {
      width: 100%;
      height: fit-content;
      min-height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin: 20px 0;
    }

    &-artist {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    &-title {
      font-size: 18px;
      font-weight: 200;
      font-style: italic;
      margin-bottom: 10px;
    }

    &-year {
      font-size: 18px;
      font-weight: 200;
      font-style: italic;
      color: #828282;
    }

    &-medium {
      color: #828282;
      margin-bottom: 5px;
    }

    &-size {
      color: #828282;
    }

    &-input_title {
      margin-bottom: 10px;
    }

    &-input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      outline: none;
      border: 1px solid #d9d9d9;
      margin-bottom: 20px;
    }

    &-textarea {
      width: 100%;
      height: 150px;
      padding: 10px;
      outline: none;
      border: 1px solid #d9d9d9;
      resize: none;
    }

    &-btn {
      width: 250px;
      height: fit-content;
      border: 0;
      background-color: #000;
      color: #fff;
      padding: 20px 50px;
      font-size: 15px;
      letter-spacing: 1px;
      cursor: pointer;
      margin: 0 auto;
    }
  }
`;

const MobileBox = styled.div`
  width: 90vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 4;
  overflow: auto;
  padding: 20px;

  .icon {
    width: 14px;
    height: auto;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .img_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      overflow: auto;

      &-img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        object-position: center;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    display: flex;

    &-wrap {
      width: 100%;
      height: fit-content;
      min-height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin: 20px 0;
    }

    &-artist {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    &-title {
      font-size: 15px;
      font-weight: 200;
      font-style: italic;
      margin-bottom: 10px;
    }

    &-year {
      font-size: 15px;
      font-weight: 200;
      font-style: italic;
      color: #828282;
    }

    &-medium {
      color: #828282;
      margin-bottom: 5px;
    }

    &-size {
      color: #828282;
    }

    &-input_title {
      margin-bottom: 10px;
    }

    &-input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      outline: none;
      border: 1px solid #d9d9d9;
      margin-bottom: 20px;
    }

    &-textarea {
      width: 100%;
      height: 150px;
      padding: 10px;
      outline: none;
      border: 1px solid #d9d9d9;
      resize: none;
    }

    &-btn {
      width: 200px;
      height: fit-content;
      border: 0;
      background-color: #000;
      color: #fff;
      font-weight: 400;
      padding: 20px 30px;
      letter-spacing: 1px;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
`;
