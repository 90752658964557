import styled from 'styled-components';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function Artist() {
  const history = useHistory();

  const [artists, setArtists] = useState<any[]>([]);

  const handleABC = (index: number) => {
    switch (index) {
      case 1:
        return 'A';

      case 2:
        return 'B';

      case 3:
        return 'C';

      case 4:
        return 'D';

      case 5:
        return 'E';

      case 6:
        return 'F';

      case 7:
        return 'G';

      case 8:
        return 'H';

      case 9:
        return 'I';

      case 10:
        return 'J';

      case 11:
        return 'K';

      case 12:
        return 'L';

      case 13:
        return 'M';

      case 14:
        return 'N';

      case 15:
        return 'O';

      case 16:
        return 'P';

      case 17:
        return 'Q';

      case 18:
        return 'R';

      case 19:
        return 'S';

      case 20:
        return 'T';

      case 21:
        return 'U';

      case 22:
        return 'V';

      case 23:
        return 'W';

      case 24:
        return 'X';

      case 25:
        return 'Y';

      case 26:
        return 'Z';

      default:
        break;
    }
  };

  const handleArtist = (id: any) => {
    history.push(`/artist/${id}`);
  };

  const getArtist = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist/abc`)
      .then((response) => {
        setArtists(response.data.data);
      });
  };

  useEffect(() => {
    getArtist();
  }, []);

  return (
    <Box>
      <span className={cn('title')}>Artist</span>

      <div className={cn('list')}>
        {artists.map((arr: any[], i) => {
          if (i === 0) return <></>;

          return (
            <div key={i} className={cn('list-wrap')}>
              <span className={cn('list-wrap-title')}>{handleABC(i)}</span>

              <div className={cn('list-wrap-artists')}>
                {arr.map((artist: any, index_2: number) => {
                  return (
                    <div
                      key={index_2}
                      className={cn('list-wrap-artists-item')}
                      onClick={() => handleArtist(artist.id)}
                    >
                      <img
                        className={cn('list-wrap-artists-item-img')}
                        src={artist.thumbnail}
                        alt='artist_img'
                        draggable={false}
                      />

                      <div className={cn('list-wrap-artists-item-wrap')}>
                        <span
                          className={cn('list-wrap-artists-item-wrap-name')}
                        >
                          {artist.name}
                        </span>

                        <span
                          className={cn('list-wrap-artists-item-wrap-text')}
                        >
                          {artist.country}, b. {artist.year_of_birth}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;

  .title {
    width: fit-content;
    height: fit-content;
    font-family: 'Noto Serif';
    font-size: 20px;
    font-weight: 400;
    margin: 30px 0;
  }

  .list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0;
      }

      &-title {
        width: 16px;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Noto Serif';
        font-weight: 500;
        font-size: 18px;
        margin-right: 30px;
        user-select: none;
      }

      &-artists {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        &-item {
          width: 100%;
          height: 80px;
          display: flex;
          flex-direction: row;
          cursor: pointer;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          &-img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            object-position: center;
          }

          &-wrap {
            width: calc(100% - 70px);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: column;
            background-color: #000;
            padding: 10px;

            &-name {
              font-size: 15px;
              font-weight: 500;
              margin-bottom: 5px;
              user-select: none;
              color: #fff;
            }

            &-text {
              color: #dee2e6;
              user-select: none;
            }
          }
        }
      }
    }
  }
`;
