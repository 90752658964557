export const theme = {
  primary: '#2a2a2a',
  secondary: '#1F2E47',
  info: '#5CADFF',
  success: '#41C86E',
  warning: '#FCD75F',
  error: '#F1414D',
  processing: '#75A9A9',
  background: '#F1F3F5',
  font: '#0E1116',
  font_gray: '#424242',
  font_gray_2: '#555555',
  font_dark_gray: '#455A64',
  white: '#FFFFFF',
  gray: '#ADB5BD',
  light_gray: '#D3D3D3',
  black: '#000000',
  yellow: '#ffc324',
  light_blue: '#DCEFFC',
  light_blue_second: '#D4EFF2',
  light_blue_third: '#B4DBF6',
  dark_blue: '#90C7D9',
  orange: '#DF8684',
  dark_yellow: '#DFC790',
};

export type ThemeType = typeof theme;
