import { isBrowser } from 'react-device-detect';
import Navigation from '../components/common/Navigation';

import Component from '../components/online_exhibition/Detail';
import MobileComponent from '../components/mobile_online_exhibition/Detail';

function OnlineExhibitionDetail() {
  return (
    <Navigation>{isBrowser ? <Component /> : <MobileComponent />}</Navigation>
  );
}

export default OnlineExhibitionDetail;
