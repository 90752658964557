import { isBrowser } from 'react-device-detect';

import Navigation from '../components/common/Navigation';

import Component from '../components/artfair';
import MobileComponent from '../components/mobile_artfair';

function ArtFair() {
  return (
    <Navigation>{isBrowser ? <Component /> : <MobileComponent />}</Navigation>
  );
}

export default ArtFair;
