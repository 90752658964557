import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function utcDate(date) {
  const utc_date = dayjs(date).utc();

  return utc_date;
}

export function getUTCDate(date) {
  const utc_date = dayjs.utc(date);

  const local_date = utc_date.local();

  return local_date;
}
