import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import ICON_1 from '../../static/images/location.svg';
import ICON_2 from '../../static/images/calendar.svg';
import ICON_3 from '../../static/images/link.svg';
import { useHistory, useParams } from 'react-router-dom';
import { getUTCDate } from '../../library/date.lib';
import Color from 'color';

export default function JournalDetail() {
  const { id }: any = useParams();
  const history = useHistory();

  const [journal, setJournal] = useState<any>(null);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const getJournal = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal/${id}`)
      .then((response) => {
        setJournal(response.data.data);
      });
  };

  useEffect(() => {
    getJournal();
  }, []);

  return (
    <>
      {journal ? (
        <Box>
          <div className={cn('journal_contents')}>
            <span className={cn('journal_contents-title')}>
              {journal.title}
            </span>

            <div className={cn('journal_contents-wrap')}>
              <div className={cn('journal_contents-wrap-left_panel')}>
                <span className={cn('journal_contents-wrap-left_panel-title')}>
                  ARTISTS
                </span>

                <div className={cn('journal_contents-wrap-left_panel-artists')}>
                  {journal.artists.map((artist: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={cn(
                          'journal_contents-wrap-left_panel-artists-text'
                        )}
                        onClick={() => history.push(`/artist/${artist.id}`)}
                      >
                        {artist.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={cn('journal_contents-wrap-right_panel')}>
                <div
                  className={cn('journal_contents-wrap-right_panel-icon_wrap')}
                >
                  <img
                    className={cn(
                      'journal_contents-wrap-right_panel-icon_wrap-icon'
                    )}
                    src={ICON_1}
                    alt='icon'
                    style={{ width: '12px', height: 'auto' }}
                    draggable={false}
                  />

                  <span
                    className={cn(
                      'journal_contents-wrap-right_panel-icon_wrap-text'
                    )}
                  >
                    {journal.location}
                  </span>
                </div>

                {journal.link ? (
                  <div
                    className={cn(
                      'journal_contents-wrap-right_panel-icon_wrap'
                    )}
                  >
                    <img
                      className={cn(
                        'journal_contents-wrap-right_panel-icon_wrap-icon'
                      )}
                      src={ICON_3}
                      alt='icon'
                      style={{ width: '12px', height: 'auto' }}
                      draggable={false}
                    />

                    <span
                      className={cn(
                        'journal_contents-wrap-right_panel-icon_wrap-text',
                        'journal_contents-wrap-right_panel-icon_wrap-text-link'
                      )}
                      onClick={() => window.open(journal.link, '_blank')}
                    >
                      {journal.link}
                    </span>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  className={cn('journal_contents-wrap-right_panel-icon_wrap')}
                >
                  <img
                    className={cn(
                      'journal_contents-wrap-right_panel-icon_wrap-icon'
                    )}
                    src={ICON_2}
                    alt='icon'
                    style={{ width: '14px', height: 'auto' }}
                    draggable={false}
                  />

                  <span
                    className={cn(
                      'journal_contents-wrap-right_panel-icon_wrap-text'
                    )}
                  >
                    {`${getUTCDate(String(journal.date).split('|')[0])
                      .format('MM.DD.YYYY')
                      .toString()} - ${getUTCDate(
                      String(journal.date).split('|')[1]
                    )
                      .format('MM.DD.YYYY')
                      .toString()}`}
                  </span>
                </div>
              </div>
            </div>

            {journal.image ? (
              <img
                className={cn('journal_contents-img')}
                src={journal.image}
                alt='journal_img'
                draggable={false}
              />
            ) : (
              <></>
            )}

            <div className={cn('journal_contents-text')}>
              {handleSentence(journal.about)}
            </div>

            <span className={cn('journal_contents-date')}>
              {dayjs(journal.created_at).format('MM / DD / YYYY')}
            </span>
          </div>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .journal_contents {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 50px;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      &-left_panel {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &-title {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        &-artists {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;

          &-text {
            width: fit-content;
            height: fit-content;
            padding: 5px 10px;
            background-color: ${Color('#ddd').alpha(0.3).hsl().toString()};
            border-radius: 4px;
            user-select: none;
            margin-bottom: 10px;
            line-height: 2;
          }
        }
      }

      &-right_panel {
        width: 100%;
        height: initial;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        &-icon_wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          &-icon {
            margin-right: 10px;
          }

          &-text {
            letter-spacing: 1px;

            &-link {
              color: #228be6;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &-img {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }

    &-text {
      width: 100%;
      height: fit-content;
      line-height: 1.8;
    }

    &-date {
      width: fit-content;
      height: fit-content;
      margin-top: 20px;
      margin-bottom: 50px;
      color: #898989;
      font-weight: 400;
      letter-spacing: 1px;
      font-style: italic;
    }
  }
`;
